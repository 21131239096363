import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Heart: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Heart"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.0062 20.5698L3.29026 12.6749C-1.4467 7.93796 5.51661 -1.15699 12.0062 6.20107C18.4958 -1.15699 25.4277 7.96954 20.7223 12.6749L12.0062 20.5698Z" />
    </svg>
  </span>
);

export default Heart;
