import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Volume: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-none',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Drop"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 250 250"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M124.969 218.75C104.143 218.75 86.7888 211.59 72.9069 197.271C59.025 182.944 52.084 165.115 52.084 143.781C52.084 134.337 54.2368 124.823 58.5423 115.239C62.8409 105.656 68.2125 96.4617 74.6569 87.6562C81.1152 78.8437 88.0805 70.5381 95.5527 62.7395C103.032 54.9339 109.976 48.1423 116.386 42.3645C117.636 41.2464 119.001 40.3819 120.48 39.7708C121.952 39.1666 123.459 38.8645 125.001 38.8645C126.542 38.8645 128.049 39.1666 129.521 39.7708C130.994 40.3749 132.358 41.243 133.615 42.3749C140.025 48.1388 146.969 54.927 154.449 62.7395C161.928 70.5381 168.893 78.8402 175.344 87.6457C181.796 96.4513 187.171 105.649 191.469 115.239C195.768 124.83 197.917 134.344 197.917 143.781C197.917 165.115 190.966 182.944 177.063 197.271C163.146 211.59 145.789 218.75 124.969 218.75ZM125.001 208.333C143.056 208.333 157.987 202.212 169.792 189.969C181.598 177.726 187.501 162.319 187.501 143.75C187.501 131.076 182.247 116.753 171.74 100.781C161.233 84.8089 145.653 67.361 125.001 48.4374C104.341 67.361 88.7611 84.8089 78.2611 100.781C67.7611 116.753 62.5076 131.076 62.5007 143.75C62.5007 162.326 68.4034 177.736 80.209 189.979C92.0145 202.222 106.945 208.34 125.001 208.333ZM127.261 194.708C128.81 194.535 130.025 194.003 130.907 193.114C131.775 192.212 132.209 191.132 132.209 189.875C132.209 188.403 131.709 187.26 130.709 186.448C129.709 185.642 128.435 185.326 126.886 185.5C119.768 186.021 111.817 183.799 103.032 178.833C94.2402 173.875 88.8027 165.576 86.7194 153.937C86.3722 152.299 85.7958 151.073 84.9902 150.26C84.1777 149.448 83.1534 149.042 81.9173 149.042C80.5493 149.042 79.3861 149.552 78.4277 150.573C77.4694 151.594 77.1812 153.121 77.5632 155.156C80.2507 169.615 86.9277 179.962 97.5944 186.198C108.261 192.434 118.15 195.271 127.261 194.708Z" />
    </svg>
  </span>
);

export default Volume;
