import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const BioSuccess: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-[#E6F3EC]',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="BioSuccess"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#216E48" />
      <path
        d="M5.08984 14.6505L8.25097 18.7147C8.35764 18.8534 8.49432 18.966 8.65074 19.0443C8.80717 19.1225 8.97928 19.1643 9.15415 19.1663C9.32621 19.1684 9.49655 19.1319 9.65279 19.0599C9.80901 18.9878 9.94721 18.8817 10.0573 18.7495L20.1428 6.54504"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default BioSuccess;
