import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const ReplyAll: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="ReplyAll"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 6L10.5 1M5.5 6L10.5 11M5.5 6H15.5C17.167 6 20.5 7 20.5 11"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 1L1 6L6 11"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default ReplyAll;
