import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const CheckmarkCircle: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="CheckmarkCircle"
  >
    <svg width="100%" height="100%" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 31.6812L21.9332 38.6667C22.1165 38.9049 22.3514 39.0985 22.6203 39.2331C22.8891 39.3674 23.185 39.4393 23.4855 39.4428C23.7812 39.4464 24.074 39.3837 24.3426 39.26C24.6111 39.136 24.8486 38.9537 25.0379 38.7264L42.3723 17.75"
        stroke="#457A1D"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="28.5" cy="28.5" r="27.5" stroke="#457A1D" strokeWidth="2" />
    </svg>
  </span>
);

export default CheckmarkCircle;
