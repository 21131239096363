import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Email: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Email"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7581 1.64062H3.24449C2.36517 1.64062 1.65234 2.35346 1.65234 3.23277V16.766C1.65234 17.6453 2.36517 18.3581 3.24449 18.3581H20.7581C21.6374 18.3581 22.3502 17.6453 22.3502 16.766V3.23277C22.3502 2.35346 21.6374 1.64062 20.7581 1.64062Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.65234 3.63086L10.9823 9.24195C11.2684 9.40991 11.629 9.50189 12.0013 9.50189C12.3736 9.50189 12.7342 9.40991 13.0202 9.24195L22.3502 3.63086"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Email;
