import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Smile: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Smile"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9993 22.3502C17.715 22.3502 22.3482 17.7169 22.3482 12.0013C22.3482 6.28572 17.715 1.65234 11.9993 1.65234C6.28377 1.65234 1.65039 6.28572 1.65039 12.0013C1.65039 17.7169 6.28377 22.3502 11.9993 22.3502Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74609 13.5938C7.54217 16.4596 10.7265 18.211 13.5923 17.4149C15.3437 16.778 16.7766 15.3451 17.2543 13.5938"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.41757 9.61248C8.19774 9.61248 8.01953 9.43427 8.01953 9.21444C8.01953 8.99462 8.19774 8.81641 8.41757 8.81641"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.41797 9.61248C8.6378 9.61248 8.816 9.43427 8.816 9.21444C8.816 8.99462 8.6378 8.81641 8.41797 8.81641"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5816 9.61248C15.3618 9.61248 15.1836 9.43427 15.1836 9.21444C15.1836 8.99462 15.3618 8.81641 15.5816 8.81641"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.582 9.61248C15.8019 9.61248 15.9801 9.43427 15.9801 9.21444C15.9801 8.99462 15.8019 8.81641 15.582 8.81641"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Smile;
