import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Bell: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Bell"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40771 19.9509H12.592"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3685 8.00968C17.3685 6.32063 16.6976 4.70077 15.5032 3.50642C14.3089 2.31209 12.689 1.64111 11 1.64111C9.31089 1.64111 7.69103 2.31209 6.49669 3.50642C5.30235 4.70077 4.63138 6.32063 4.63138 8.00968V13.5822C4.63138 14.2156 4.37976 14.8231 3.93189 15.271C3.48402 15.7188 2.87655 15.9704 2.24316 15.9704H19.7567C19.1234 15.9704 18.5158 15.7188 18.0679 15.271C17.6201 14.8231 17.3685 14.2156 17.3685 13.5822V8.00968Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Bell;
