import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Facebook: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Facebook"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65088 20.7568V3.24327C1.65088 2.821 1.81862 2.41604 2.11721 2.11745C2.41579 1.81887 2.82075 1.65112 3.24302 1.65112H20.7566C21.1788 1.65112 21.5839 1.81887 21.8824 2.11745C22.1809 2.41604 22.3487 2.821 22.3487 3.24327V20.7568C22.3487 21.1791 22.1809 21.5841 21.8824 21.8826C21.5839 22.1812 21.1788 22.349 20.7566 22.349H15.9802V14.8022H17.1106C17.3682 14.8022 17.6151 14.6999 17.7973 14.5178C17.9794 14.3356 18.0818 14.0886 18.0818 13.831V12.6051C18.0818 12.4775 18.0566 12.3512 18.0079 12.2334C17.959 12.1156 17.8876 12.0085 17.7973 11.9183C17.7072 11.8281 17.6 11.7566 17.4822 11.7078C17.3644 11.659 17.2381 11.6339 17.1106 11.6339H16.0439V10.1372C16.0439 8.79985 16.6489 8.79984 17.2539 8.79984H18.034C18.1622 8.80513 18.2899 8.78219 18.4082 8.73267C18.5265 8.68316 18.6325 8.60825 18.7186 8.51326C18.8112 8.42518 18.8844 8.31884 18.9337 8.20101C18.9831 8.08316 19.0075 7.95637 19.0052 7.82864V6.65045C19.0095 6.5208 18.9882 6.39159 18.9423 6.2702C18.8967 6.1488 18.8276 6.0376 18.7387 5.94297C18.65 5.84835 18.5435 5.77211 18.4254 5.71867C18.3072 5.66522 18.1796 5.63559 18.0499 5.63148H16.219C15.6847 5.61139 15.1525 5.70752 14.659 5.91323C14.1656 6.11891 13.7226 6.42929 13.3608 6.82288C12.999 7.21649 12.727 7.68393 12.5634 8.19294C12.3999 8.70194 12.3489 9.24038 12.4138 9.77105V11.6339H11.3948C11.2659 11.6317 11.1379 11.6553 11.0182 11.7032C10.8986 11.751 10.7896 11.8222 10.6977 11.9126C10.6059 12.003 10.5329 12.1108 10.4831 12.2297C10.4333 12.3486 10.4076 12.4762 10.4077 12.6051V13.831C10.4076 13.9599 10.4333 14.0875 10.4831 14.2064C10.5329 14.3253 10.6059 14.4331 10.6977 14.5235C10.7896 14.6139 10.8986 14.6851 11.0182 14.7329C11.1379 14.7808 11.2659 14.8043 11.3948 14.8022H12.4138V22.349H3.24302C2.82075 22.349 2.41579 22.1812 2.11721 21.8826C1.81862 21.5841 1.65088 21.1791 1.65088 20.7568Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Facebook;
