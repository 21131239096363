import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Cog: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Cog"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18176 4.43737L8.86638 2.67009C8.98184 2.37087 9.18499 2.1135 9.44919 1.93168C9.7134 1.74986 10.0264 1.65207 10.3471 1.65112H11.6526C11.9734 1.65207 12.2863 1.74986 12.5505 1.93168C12.8147 2.1135 13.0179 2.37087 13.1333 2.67009L13.8179 4.43737L16.1424 5.77477L18.0211 5.48819C18.334 5.44572 18.6524 5.49721 18.936 5.63613C19.2194 5.77504 19.4552 5.99509 19.6133 6.26834L20.2501 7.38284C20.4133 7.66043 20.4886 7.98097 20.4659 8.30217C20.4431 8.62337 20.3235 8.93013 20.1228 9.18196L18.9605 10.6627V13.3375L20.1546 14.8181C20.3554 15.07 20.475 15.3767 20.4977 15.6979C20.5205 16.0191 20.4452 16.3397 20.282 16.6173L19.6451 17.7318C19.487 18.005 19.2512 18.225 18.9678 18.364C18.6843 18.5028 18.3658 18.5544 18.053 18.5119L16.1743 18.2253L13.8498 19.5627L13.1652 21.33C13.0497 21.6292 12.8466 21.8866 12.5824 22.0684C12.3182 22.2503 12.0052 22.348 11.6845 22.349H10.3471C10.0264 22.348 9.7134 22.2503 9.44919 22.0684C9.18499 21.8866 8.98184 21.6292 8.86638 21.33L8.18176 19.5627L5.85723 18.2253L3.9785 18.5119C3.66568 18.5544 3.34728 18.5028 3.06379 18.364C2.78031 18.225 2.54451 18.005 2.38636 17.7318L1.7495 16.6173C1.58631 16.3397 1.51113 16.0191 1.53386 15.6979C1.5566 15.3767 1.6762 15.07 1.87688 14.8181L3.03914 13.3375V10.6627L1.84503 9.18196C1.64436 8.93013 1.52476 8.62337 1.50202 8.30217C1.47929 7.98097 1.55447 7.66043 1.71766 7.38284L2.35452 6.26834C2.51267 5.99509 2.74846 5.77504 3.03194 5.63613C3.31544 5.49721 3.63384 5.44572 3.94666 5.48819L5.82539 5.77477L8.18176 4.43737ZM7.81557 12.0001C7.81557 12.6298 8.00233 13.2455 8.35222 13.7691C8.70212 14.2928 9.19943 14.7009 9.78128 14.9419C10.3631 15.183 11.0034 15.246 11.6211 15.1232C12.2388 15.0003 12.8062 14.697 13.2515 14.2517C13.6968 13.8064 14.0001 13.239 14.123 12.6213C14.2458 12.0036 14.1828 11.3633 13.9418 10.7815C13.7007 10.1996 13.2926 9.7023 12.7689 9.35241C12.2453 9.00252 11.6296 8.81577 10.9999 8.81577C10.1553 8.81577 9.3454 9.15125 8.74823 9.74843C8.15106 10.3456 7.81557 11.1555 7.81557 12.0001Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Cog;
