import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const User: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="User"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 13.5923C14.1982 13.5923 15.9802 11.8102 15.9802 9.61195C15.9802 7.41366 14.1982 5.63159 11.9999 5.63159C9.8016 5.63159 8.01953 7.41366 8.01953 9.61195C8.01953 11.8102 9.8016 13.5923 11.9999 13.5923Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20117 19.8014C5.91165 18.6352 6.91019 17.6713 8.1008 17.0026C9.2914 16.3337 10.634 15.9824 11.9996 15.9824C13.3652 15.9824 14.7078 16.3337 15.8984 17.0026C17.0891 17.6713 18.0875 18.6352 18.7981 19.8014"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9998 22.349C17.7154 22.349 22.3487 17.7157 22.3487 12.0001C22.3487 6.2845 17.7154 1.65112 11.9998 1.65112C6.28425 1.65112 1.65088 6.2845 1.65088 12.0001C1.65088 17.7157 6.28425 22.349 11.9998 22.349Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default User;
