import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Trash: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Trash"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H17"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5H15V17C15 17.3536 14.8595 17.6928 14.6095 17.9428C14.3595 18.1928 14.0203 18.3333 13.6667 18.3333H4.33333C3.97971 18.3333 3.64057 18.1928 3.39052 17.9428C3.14048 17.6928 3 17.3536 3 17V5Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66602 5V4.33333C5.66602 3.44928 6.0172 2.60143 6.64232 1.97631C7.26744 1.35119 8.1153 1 8.99935 1C9.8834 1 10.7313 1.35119 11.3564 1.97631C11.9815 2.60143 12.3327 3.44928 12.3327 4.33333V5"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9V14.3354"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 9V14.3354"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Trash;
