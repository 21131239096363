import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const CaratUp: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="CaratUp"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.453828 11.0606C0.73512 11.3418 1.11658 11.4998 1.51433 11.4998C1.91207 11.4998 2.29354 11.3418 2.57483 11.0606L9.99983 3.63557L17.4248 11.0606C17.7077 11.3338 18.0866 11.485 18.4799 11.4816C18.8732 11.4782 19.2494 11.3204 19.5276 11.0423C19.8057 10.7642 19.9634 10.388 19.9668 9.99467C19.9703 9.60138 19.8191 9.22248 19.5458 8.93957L11.0603 0.454072C10.779 0.172866 10.3976 0.0148926 9.99983 0.0148926C9.60208 0.0148926 9.22062 0.172866 8.93933 0.454072L0.453828 8.93957C0.172622 9.22086 0.0146484 9.60233 0.0146484 10.0001C0.0146484 10.3978 0.172622 10.7793 0.453828 11.0606Z" />
    </svg>
  </span>
);

export default CaratUp;
