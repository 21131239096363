import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Inbox: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Inbox"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3487 13.5923H16.7762C16.354 13.5923 15.949 13.76 15.6504 14.0586C15.3518 14.3572 15.1841 14.7622 15.1841 15.1844C15.1841 16.0289 14.8486 16.8388 14.2514 17.436C13.6543 18.0332 12.8443 18.3687 11.9998 18.3687C11.1553 18.3687 10.3453 18.0332 9.74818 17.436C9.151 16.8388 8.81552 16.0289 8.81552 15.1844C8.81552 14.7622 8.64777 14.3572 8.3492 14.0586C8.05061 13.76 7.64565 13.5923 7.22338 13.5923H1.65088V20.7569C1.65088 21.1792 1.81862 21.5842 2.11721 21.8827C2.41579 22.1813 2.82075 22.3491 3.24302 22.3491H20.7566C21.1788 22.3491 21.5839 22.1813 21.8824 21.8827C22.1809 21.5842 22.3487 21.1792 22.3487 20.7569V13.5923Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01953 7.22363L11.9999 11.204L15.9802 7.22363"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.65112V11.204"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Inbox;
