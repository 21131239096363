import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Plane: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Plane"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1088 18.3706L13.5765 21.8224C13.7893 22.0404 14.0553 22.1991 14.3482 22.2829C14.6411 22.3666 14.9509 22.3723 15.2467 22.2996C15.5444 22.2303 15.8193 22.0857 16.0449 21.8796C16.2706 21.6734 16.4394 21.4127 16.5352 21.1225L22.2297 4.05451C22.3487 3.73471 22.3732 3.38751 22.3004 3.05418C22.2277 2.72083 22.0608 2.4154 21.8196 2.17415C21.5784 1.9329 21.2729 1.766 20.9396 1.69328C20.6062 1.62056 20.259 1.64509 19.9393 1.76393L2.87124 7.45855C2.571 7.5611 2.30359 7.74199 2.09665 7.98248C1.88972 8.22297 1.75075 8.51437 1.69413 8.82654C1.63569 9.11047 1.6487 9.40446 1.732 9.68211C1.81529 9.95975 1.96626 10.2124 2.17134 10.4172L6.5298 14.7757L6.38663 20.2954L10.1088 18.3706Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7203 2.11328L6.5293 14.7751"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Plane;
