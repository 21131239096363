import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const BioFailure: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-[#FCEDE6]',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="BioFailure"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" fill="#A40A00" stroke="#A40A00" />
      <path
        d="M18.0347 5.96289L5.96094 18.0366"
        strokeOpacity="0.870588"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.96094 5.96289L18.0347 18.0366"
        strokeOpacity="0.870588"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default BioFailure;
