import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Pin: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Pin"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1652 8.81503C19.1652 13.5915 12.0006 19.164 12.0006 19.164C12.0006 19.164 4.83594 13.5915 4.83594 8.81503C4.83594 4.91269 8.09824 1.65039 12.0006 1.65039C15.9029 1.65039 19.1652 4.91269 19.1652 8.81503Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0015 11.2042C13.3205 11.2042 14.3897 10.1349 14.3897 8.81595C14.3897 7.49697 13.3205 6.42773 12.0015 6.42773C10.6825 6.42773 9.61328 7.49697 9.61328 8.81595C9.61328 10.1349 10.6825 11.2042 12.0015 11.2042Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4929 16.7754H19.962L22.3502 22.3479H1.65234L4.04056 16.7754H5.50963"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Pin;
