import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const BioPending: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="BioPending"
  >
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.641 7.37795L12.9997 14.9998L13.3579 7.37795C13.3601 7.32922 13.3523 7.28056 13.3351 7.23493C13.3179 7.1893 13.2915 7.14766 13.2576 7.11256C13.2238 7.07746 13.1831 7.04964 13.1381 7.03079C13.0931 7.01195 13.0448 7.00247 12.996 7.00295C12.9478 7.0034 12.9002 7.01356 12.856 7.03282C12.8118 7.05208 12.772 7.08005 12.7388 7.11505C12.7057 7.15005 12.68 7.19137 12.6632 7.23654C12.6463 7.28171 12.6388 7.3298 12.641 7.37795Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 19.9944C12.7528 19.9944 12.5111 19.9211 12.3055 19.7837C12.1 19.6464 11.9398 19.4511 11.8452 19.2227C11.7505 18.9943 11.7258 18.743 11.774 18.5005C11.8223 18.258 11.9413 18.0353 12.1161 17.8605C12.2909 17.6857 12.5137 17.5666 12.7561 17.5184C12.9986 17.4702 13.2499 17.4949 13.4784 17.5895C13.7068 17.6841 13.902 17.8444 14.0393 18.0499C14.1767 18.2555 14.25 18.4972 14.25 18.7444C14.25 19.0759 14.1183 19.3938 13.8839 19.6283C13.6495 19.8627 13.3315 19.9944 13 19.9944Z" />
    </svg>
  </span>
);

export default BioPending;
