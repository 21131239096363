import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Like: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Like"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.52025 8.10938H6.26383V18.0764H2.52025C2.31079 18.0764 2.1099 17.9933 1.96179 17.8451C1.81368 17.697 1.73047 17.4961 1.73047 17.2867V8.89916C1.73047 8.6897 1.81368 8.48881 1.96179 8.3407C2.1099 8.1926 2.31079 8.10938 2.52025 8.10938Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.26367 8.11055L10.2758 1.69751C10.4312 1.44404 10.6497 1.23515 10.9099 1.09121C11.1701 0.947262 11.4632 0.87318 11.7606 0.876141C11.9947 0.867687 12.2283 0.906265 12.4473 0.989597C12.6663 1.07293 12.8664 1.19931 13.0357 1.3613C13.2051 1.52328 13.3402 1.71756 13.4332 1.93267C13.5262 2.14777 13.5751 2.37933 13.5771 2.61366V7.2102H20.5114C20.7676 7.21851 21.0191 7.28095 21.2495 7.39345C21.4799 7.50594 21.6838 7.6659 21.848 7.86285C22.0121 8.05979 22.1327 8.28924 22.2018 8.53612C22.2709 8.78299 22.2869 9.0417 22.2489 9.29522L20.9852 17.4458C20.9314 17.8814 20.7201 18.2823 20.3915 18.5732C20.0629 18.8641 19.6392 19.0249 19.2002 19.0254H8.83836C8.3451 19.0273 7.85823 18.9138 7.41675 18.6936L6.27947 18.125"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.26367 8.10938V18.0765"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Like;
