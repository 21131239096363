import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Check: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Check"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65088 12.468L5.99743 18.0563C6.1441 18.2469 6.33203 18.4018 6.54712 18.5095C6.7622 18.6169 6.99885 18.6744 7.2393 18.6773C7.47588 18.6801 7.7101 18.63 7.92492 18.531C8.13974 18.4318 8.32976 18.2859 8.48117 18.1041L22.3487 1.323"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Check;
