import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Comment: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Comment"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02092 20.7542L1.65234 22.3463L3.24449 17.5699V3.24058C3.24449 2.81831 3.41223 2.41335 3.71081 2.11477C4.0094 1.81618 4.41436 1.64844 4.83663 1.64844H20.7581C21.1803 1.64844 21.5853 1.81618 21.8839 2.11477C22.1824 2.41335 22.3502 2.81831 22.3502 3.24058V19.162C22.3502 19.5842 22.1824 19.9893 21.8839 20.2878C21.5853 20.5863 21.1803 20.7542 20.7581 20.7542H8.02092Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01953 8.8125H17.5724"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01953 13.5898H14.3881"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Comment;
