import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const XLogo: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="XLogo"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.9746 16.948L11.029 7.60492L16.9165 1.12492C16.9737 1.07179 17.0193 1.00732 17.0502 0.935624C17.0812 0.863925 17.0969 0.786563 17.0963 0.708467C17.0957 0.630372 17.0789 0.553252 17.0469 0.48202C17.0148 0.410788 16.9683 0.347004 16.9103 0.294731C16.8523 0.242457 16.784 0.202839 16.7098 0.178399C16.6357 0.153959 16.5572 0.145233 16.4795 0.152777C16.4017 0.16032 16.3264 0.183968 16.2583 0.222214C16.1903 0.26046 16.1309 0.312467 16.084 0.374922L10.4065 6.62055L6.47835 0.448047C6.42724 0.367679 6.35656 0.301606 6.27293 0.256021C6.1893 0.210436 6.09547 0.186832 6.00023 0.187422H1.50023C1.39947 0.187468 1.30057 0.214581 1.21386 0.265925C1.12716 0.317269 1.05585 0.39096 1.00737 0.479295C0.95889 0.567629 0.93503 0.667364 0.938281 0.768075C0.941533 0.868785 0.971778 0.966774 1.02585 1.0518L6.97148 10.3949L1.08398 16.8749C0.999624 16.9873 0.96037 17.1271 0.973941 17.267C0.987512 17.4068 1.05292 17.5365 1.15731 17.6306C1.2617 17.7246 1.39749 17.7762 1.53799 17.7751C1.67848 17.7741 1.8135 17.7205 1.91648 17.6249L7.59398 11.3793L11.5221 17.5518C11.5732 17.6322 11.6439 17.6982 11.7275 17.7438C11.8112 17.7894 11.905 17.813 12.0002 17.8124H16.5002C16.601 17.8124 16.6999 17.7853 16.7866 17.7339C16.8733 17.6826 16.9446 17.6089 16.9931 17.5205C17.0416 17.4322 17.0654 17.3325 17.0622 17.2318C17.0589 17.1311 17.0287 17.0331 16.9746 16.948ZM12.3087 16.6874L2.52492 1.31242H5.69179L15.4755 16.6874H12.3087Z" />
    </svg>
  </span>
);

export default XLogo;
