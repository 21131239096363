import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Flag: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-none',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Flag"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.763136 2.39452C0.640676 2.50067 0.542596 2.63203 0.475607 2.77959C0.408619 2.92716 0.374304 3.08746 0.375011 3.24952V18.9995C0.375011 19.2979 0.493537 19.584 0.704515 19.795C0.915494 20.006 1.20164 20.1245 1.50001 20.1245C1.79838 20.1245 2.08453 20.006 2.29551 19.795C2.50648 19.584 2.62501 19.2979 2.62501 18.9995V15.0358C4.76626 13.4308 6.57189 14.1808 9.25126 15.5045C10.7728 16.2545 12.5081 17.117 14.3888 17.117C15.7688 17.117 17.2275 16.6539 18.7369 15.347C18.8584 15.2417 18.9559 15.1116 19.0229 14.9654C19.0898 14.8192 19.1246 14.6603 19.125 14.4995V3.24952C19.1251 3.03382 19.0631 2.82265 18.9465 2.64117C18.8299 2.4597 18.6636 2.31557 18.4674 2.22596C18.2712 2.13635 18.0533 2.10504 17.8398 2.13576C17.6263 2.16647 17.4261 2.25792 17.2631 2.39921C14.9466 4.4064 13.0884 3.64702 10.2488 2.24171C7.61157 0.931086 4.32845 -0.693602 0.763136 2.39452ZM16.875 13.9614C14.7338 15.5673 12.9281 14.8155 10.2488 13.4926C8.07751 12.4145 5.46845 11.1245 2.62501 12.4248V3.78296C4.76626 2.17796 6.57189 2.92796 9.25126 4.25171C10.7728 5.00171 12.5081 5.86421 14.3888 5.86421C15.2472 5.8655 16.0957 5.67962 16.875 5.31952V13.9614Z"
        fill="#1D1D1D"
      />
    </svg>
  </span>
);

export default Flag;
