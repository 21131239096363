import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Reply: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Reply"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 6L6.5 1M1.5 6L6.5 11M1.5 6H11.5C13.167 6 16.5 7 16.5 11"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Reply;
