import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Printer: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Printer"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5738 18.3685H20.7581C21.1803 18.3685 21.5853 18.2006 21.8839 17.9021C22.1824 17.6036 22.3502 17.1986 22.3502 16.7763V9.61167C22.3502 9.18941 22.1824 8.78444 21.8839 8.48585C21.5853 8.18728 21.1803 8.01953 20.7581 8.01953H3.24449C2.82222 8.01953 2.41726 8.18728 2.11867 8.48585C1.82009 8.78444 1.65234 9.18941 1.65234 9.61167V16.7763C1.65234 17.1986 1.82009 17.6036 2.11867 17.9021C2.41726 18.2006 2.82222 18.3685 3.24449 18.3685H6.42877"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.42969 15.9805V21.553C6.42969 21.7641 6.51356 21.9666 6.66286 22.116C6.81214 22.2651 7.01462 22.349 7.22576 22.349H16.7786C16.9897 22.349 17.1923 22.2651 17.3416 22.116C17.4908 21.9666 17.5747 21.7641 17.5747 21.553V15.9805H6.42969Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5747 8.01994V2.44744C17.5747 2.23631 17.4908 2.03382 17.3416 1.88453C17.1923 1.73524 16.9897 1.65137 16.7786 1.65137H7.22576C7.01462 1.65137 6.81214 1.73524 6.66286 1.88453C6.51356 2.03382 6.42969 2.23631 6.42969 2.44744V8.01994"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3695 12H16.7773"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Printer;
