import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Magnifier: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Magnifier"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.76923 15.0385C11.5078 15.0385 14.5385 12.0078 14.5385 8.26923C14.5385 4.53068 11.5078 1.5 7.76923 1.5C4.03068 1.5 1 4.53068 1 8.26923C1 12.0078 4.03068 15.0385 7.76923 15.0385Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9991 17.4991L12.6914 13.1914"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Magnifier;
