import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Photo: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Photo"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.24253 16.9605H20.7561C21.6354 16.9605 22.3482 16.2477 22.3482 15.3683V2.63121C22.3482 1.75188 21.6354 1.03906 20.7561 1.03906H3.24253C2.36322 1.03906 1.65039 1.75188 1.65039 2.63121V15.3683C1.65039 16.2477 2.36322 16.9605 3.24253 16.9605Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9825 7.74629C17.0774 7.74629 17.9651 6.85869 17.9651 5.76377C17.9651 4.66885 17.0774 3.78125 15.9825 3.78125C14.8876 3.78125 14 4.66885 14 5.76377C14 6.85869 14.8876 7.74629 15.9825 7.74629Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3153 16.9613C14.8209 14.3281 13.407 11.9562 11.3259 10.2689C9.24486 8.58148 6.63197 7.68842 3.95346 7.74898C3.18066 7.74693 2.40945 7.81891 1.65039 7.96394"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3481 12.0915C21.094 11.668 19.7792 11.4524 18.4555 11.4532C16.7801 11.4495 15.1216 11.7886 13.582 12.4497"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Photo;
