import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const CaratLeft: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="CaratLeft"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.0603 0.454072C11.3415 0.735364 11.4995 1.11683 11.4995 1.51457C11.4995 1.91232 11.3415 2.29378 11.0603 2.57507L3.63533 10.0001L11.0603 17.4251C11.3336 17.708 11.4848 18.0869 11.4813 18.4802C11.4779 18.8735 11.3202 19.2497 11.0421 19.5278C10.7639 19.8059 10.3877 19.9637 9.99443 19.9671C9.60113 19.9705 9.22223 19.8193 8.93933 19.5461L0.453828 11.0606C0.172621 10.7793 0.0146484 10.3978 0.0146484 10.0001C0.0146484 9.60233 0.172621 9.22086 0.453828 8.93957L8.93933 0.454072C9.22062 0.172866 9.60208 0.0148926 9.99983 0.0148926C10.3976 0.0148926 10.779 0.172866 11.0603 0.454072Z" />
    </svg>
  </span>
);

export default CaratLeft;
