import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Pencil: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Pencil"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81552 20.3428L1.65088 22.3489L3.65698 15.1843L16.7762 2.12869C16.9245 1.97705 17.1017 1.85656 17.297 1.7743C17.4925 1.69203 17.7025 1.64966 17.9146 1.64966C18.1267 1.64966 18.3367 1.69203 18.5322 1.7743C18.7276 1.85656 18.9048 1.97705 19.053 2.12869L21.8711 4.96271C22.0203 5.11071 22.1387 5.28682 22.2196 5.48082C22.3005 5.67484 22.342 5.88295 22.342 6.09313C22.342 6.30331 22.3005 6.51142 22.2196 6.70543C22.1387 6.89945 22.0203 7.07554 21.8711 7.22355L8.81552 20.3428Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Pencil;
