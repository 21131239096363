import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const CaratDown: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="CaratDown"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.453828 0.939424C0.73512 0.658217 1.11658 0.500244 1.51433 0.500244C1.91207 0.500244 2.29354 0.658217 2.57483 0.939424L9.99983 8.36442L17.4248 0.939424C17.7077 0.666187 18.0866 0.514995 18.4799 0.518412C18.8732 0.52183 19.2494 0.679584 19.5276 0.957696C19.8057 1.23581 19.9634 1.61203 19.9668 2.00532C19.9703 2.39862 19.8191 2.77752 19.5458 3.06042L11.0603 11.5459C10.779 11.8271 10.3976 11.9851 9.99983 11.9851C9.60208 11.9851 9.22062 11.8271 8.93933 11.5459L0.453828 3.06042C0.172622 2.77913 0.0146484 2.39767 0.0146484 1.99992C0.0146484 1.60218 0.172622 1.22072 0.453828 0.939424Z" />
    </svg>
  </span>
);

export default CaratDown;
