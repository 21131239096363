import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Phone: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Phone"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.47532 9.2096C1.84419 8.23116 1.56907 7.06522 1.69628 5.90781C1.82365 4.75042 2.34556 3.67214 3.17443 2.85432L3.87354 2.13935C4.1942 1.8259 4.62471 1.65039 5.07306 1.65039C5.52141 1.65039 5.95208 1.8259 6.27258 2.13935L9.27551 5.17399C9.58795 5.49193 9.76302 5.91985 9.76302 6.36562C9.76302 6.81137 9.58795 7.23931 9.27551 7.55722C8.96204 7.87783 8.78654 8.30841 8.78654 8.75679C8.78654 9.20517 8.96204 9.63574 9.27551 9.95635L14.042 14.7228C14.1987 14.8818 14.3854 15.0081 14.5913 15.0943C14.7973 15.1805 15.0183 15.2248 15.2415 15.2248C15.4648 15.2248 15.6858 15.1805 15.8917 15.0943C16.0976 15.0081 16.2844 14.8818 16.4411 14.7228C16.759 14.4104 17.1869 14.2353 17.6327 14.2353C18.0785 14.2353 18.5064 14.4104 18.8243 14.7228L21.8431 17.7415C22.1565 18.0622 22.332 18.4927 22.332 18.9411C22.332 19.3896 22.1565 19.8201 21.8431 20.1407L21.1281 20.8399C20.3103 21.6686 19.232 22.1905 18.0746 22.3178C16.9172 22.4452 15.7513 22.1701 14.7728 21.5388C9.92575 18.2499 5.75179 14.0651 2.47532 9.2096Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Phone;
