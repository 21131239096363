import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Play: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Play"
  >
    <svg width="100%" height="100%" viewBox="0 0 55 55" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.5652 3.7832H7.42991C5.41481 3.7832 3.78125 5.41676 3.78125 7.43186V47.5671C3.78125 49.5823 5.41481 51.2158 7.42991 51.2158H47.5652C49.5803 51.2158 51.2138 49.5823 51.2138 47.5671V7.43186C51.2138 5.41676 49.5803 3.7832 47.5652 3.7832Z"
        fill="#F0F0F0"
        fillOpacity="0.74"
      />
      <path
        d="M21.0391 35.6371V19.8749C21.0404 19.6111 21.1115 19.3525 21.2451 19.1251C21.3786 18.8976 21.5699 18.7096 21.7996 18.5799C22.0293 18.4503 22.2891 18.3837 22.5528 18.3868C22.8165 18.39 23.0747 18.4628 23.3012 18.5978L36.8013 26.4424C37.0286 26.5738 37.2173 26.7626 37.3485 26.99C37.4797 27.2174 37.5488 27.4752 37.5488 27.7377C37.5488 28.0002 37.4797 28.2581 37.3485 28.4855C37.2173 28.7129 37.0286 28.9017 36.8013 29.033L23.3012 36.9141C23.0747 37.0492 22.8165 37.122 22.5528 37.1251C22.2891 37.1283 22.0293 37.0617 21.7996 36.932C21.5699 36.8024 21.3786 36.6143 21.2451 36.3869C21.1115 36.1595 21.0404 35.9008 21.0391 35.6371Z"
        fill="#1D1D1D"
      />
    </svg>
  </span>
);

export default Play;
