import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Page: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-none',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Page"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7567 20.7568C18.7567 21.1791 18.5889 21.5841 18.2904 21.8826C17.9919 22.1812 17.5868 22.349 17.1646 22.349H2.83531C2.41304 22.349 2.00808 22.1812 1.70949 21.8826C1.41091 21.5841 1.24316 21.1791 1.24316 20.7568V3.24327C1.24316 2.821 1.41091 2.41604 1.70949 2.11745C2.00808 1.81887 2.41304 1.65112 2.83531 1.65112H13.1842L18.7567 7.22362V20.7568Z"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3882 1.65112V8.01969H18.7568"
        strokeWidth="1.71"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Page;
