import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Scale: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-none',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Scale"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 250 250"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9286 79.25C47.9286 61.3994 62.3994 46.9286 80.25 46.9286H170.75C188.6 46.9286 203.071 61.3994 203.071 79.25V169.75C203.071 187.6 188.6 202.071 170.75 202.071H80.25C62.3994 202.071 47.9286 187.6 47.9286 169.75V79.25ZM80.25 34C55.2591 34 35 54.2591 35 79.25V169.75C35 194.741 55.2591 215 80.25 215H170.75C195.741 215 216 194.741 216 169.75V79.25C216 54.2591 195.741 34 170.75 34H80.25ZM125.504 55.0721C97.4588 55.0721 78.0945 67.8195 67.4407 77.5239C63.5167 81.0984 61.1347 86.0577 60.7975 91.355C60.4604 96.6511 62.1936 101.871 65.6315 105.914L79.0933 121.755L79.1027 121.766C81.7435 124.86 85.4063 126.904 89.4259 127.528C93.4416 128.15 97.5473 127.314 100.999 125.17C106.962 121.489 115.842 117.396 125.512 117.396C135.182 117.396 144.062 121.489 150.025 125.17C153.477 127.314 157.582 128.15 161.598 127.528C165.617 126.904 169.28 124.86 171.921 121.766L171.933 121.753L185.374 105.916C188.812 101.874 190.548 96.6514 190.21 91.355C189.873 86.0577 187.491 81.0985 183.567 77.524C172.914 67.8197 153.549 55.0721 125.504 55.0721ZM73.6999 92.1761C73.8241 90.225 74.7014 88.3984 76.1467 87.0818C85.2573 78.7831 101.657 68.0007 125.504 68.0007C149.351 68.0007 165.751 78.7831 174.861 87.0818C176.306 88.3984 177.183 90.225 177.307 92.1761C177.432 94.1273 176.793 96.0504 175.526 97.5397L175.522 97.545L162.088 113.373C161.456 114.111 160.576 114.603 159.617 114.752C158.655 114.901 157.671 114.7 156.844 114.186L156.826 114.174C149.925 109.913 138.616 104.467 125.512 104.467C112.408 104.467 101.099 109.913 94.1981 114.174L94.1795 114.186C93.3528 114.7 92.369 114.901 91.4069 114.752C90.4467 114.603 89.5716 114.115 88.9397 113.378L75.4833 97.5421C74.2166 96.0528 73.5758 94.1273 73.6999 92.1761Z"
      />
    </svg>
  </span>
);

export default Scale;
