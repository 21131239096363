import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const CaratRight: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-black',
  stroke = 'stroke-black',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="CaratRight"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.939672 0.454072C0.658466 0.735364 0.500492 1.11683 0.500492 1.51457C0.500492 1.91232 0.658466 2.29378 0.939672 2.57507L8.36467 10.0001L0.939672 17.4251C0.666435 17.708 0.515244 18.0869 0.518661 18.4802C0.522079 18.8735 0.679832 19.2497 0.957944 19.5278C1.23606 19.8059 1.61228 19.9637 2.00557 19.9671C2.39887 19.9705 2.77777 19.8193 3.06067 19.5461L11.5462 11.0606C11.8274 10.7793 11.9854 10.3978 11.9854 10.0001C11.9854 9.60233 11.8274 9.22086 11.5462 8.93957L3.06067 0.454072C2.77938 0.172866 2.39792 0.0148926 2.00017 0.0148926C1.60243 0.0148926 1.22096 0.172866 0.939672 0.454072Z" />
    </svg>
  </span>
);

export default CaratRight;
